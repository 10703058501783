//  development url
// export const API_URL: string =
//   "https://apidwmdev.appgrowthcompany.com/api/v1/admin/";

//     live url
export const API_URL: string = "https://api.krafo.com/api/v1/admin/";

export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  getProfile: "profile",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  mediaUpload: "upload",
  signUp: "signup",
  category: "category",
  product: "product/",
  eventCategory: "eventCategory",
  shops: "shop",
  owner: "subAdmin",
  donationCategory: "donationCategory",
  user: "user",
  getUserCsv: "userExports",
  getOwnerCsv: "eventAdminsExports",
  cms: "cms",
  events: "event",
  notification: "notification",
  faqs: "faq",
  adminDashboard: "adminDashboard",
  getProductByCategory: "getProductsByCategoryId",
  productSubCategory: "subCategory",
  productSubCatById: "subCategoryById",
  featureProducts: "recommendedProducts",
  review: "review",
  replyToReview: "replyToReview",
  shopCategories: "shopCategory",
  donationAdmin: "donationAdmin",
  subAdmin: "coAdmin",
  subAdminRole: "coAdminRole",
  donationAdminsExports: "donationAdminsExports",
  donationBooking: "donationBooking",
  fundRaisingBooking: "fundRaisingBooking",
  donationBookingExport: "donationBookingExport",
  fundRaising: "fundRaising",
  plans: "membership",
  eventBooking: "eventBooking",
  banner: "getBanner",
  addBanner: "addBanner",
  deleteBanner: "deleteBanner",
  updateBanner: "updateBanner",
  logout: "logout",
  cancelEventBooking: "cancelEventBooking",
  featuredProduct: "featuredProduct",
  getProductsByUserId: "getProductsByUserId",
  userReports: "userReports",
  subscribeNewsletter: "subscribeNewsletter",
  addProduct: "product",
  boosterSubscriptions: "boosterSubscriptions",
  adminNotification: "adminNotification",
  productReports: "productReports",
  reportProduct: "reportProduct",
  adminNotificationCount: "adminNotificationCount",
  subscribeNewsletterExport: "subscribeNewsletterExport",
  sellerForm: "sellerForm",
};
