import React, { useEffect, useState } from "react";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";

const EditText = (props: {
  content: string;
  setContent: (arg0: string) => void;
}) => {
  let contentDataState: any;

  if (props.content !== " ") {
    contentDataState = ContentState.createFromBlockArray(
      convertFromHTML(`${props.content}`).contentBlocks
    );
  }

  const [editorState, setEditorState] = useState(
    props.content !== " "
      ? EditorState.createWithContent(contentDataState)
      : EditorState.createEmpty()
  );

  const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  useEffect(() => {
    props.setContent(text);
  }, [text]);

  const uploadImageCallback = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve({ data: { link: e.target?.result as string } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const getTextAlignment = () => {
    const content = editorState.getCurrentContent();
    const firstBlock = content.getFirstBlock();
    const alignment = firstBlock.getData().get("text-align") || "left"; // Default to left if not set
    return alignment;
  };

  useEffect(() => {
    // Log or use the alignment
    const alignment = getTextAlignment();
    console.log("Text alignment:", alignment); // Log alignment (left, center, right)
  }, [editorState]);

  return (
    <div>
      <Editor
        toolbar={{
          options: [
            "inline",
            "fontSize",
            "list",
            "textAlign",
            "link",
            "colorPicker",
            "image",
          ],
          inline: {
            options: ["bold", "italic", "underline"],
          },
          textAlign: {
            options: ["left", "center", "right"],
          },
          list: {
            options: ["unordered"],
          },
          image: {
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            urlEnabled: true,
            uploadEnabled: true,
            alignmentEnabled: true,
            uploadCallback: undefined,
            previewImage: false,
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            alt: { present: false, mandatory: false },
            defaultSize: {
              height: "auto",
              width: "auto",
            },
          },
        }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        editorStyle={{
          height: "40vh",
          border: "1px solid #E6E8F0",
          padding: 10,
          textAlign: "left",
        }}
      />
    </div>
  );
};
export default EditText;
